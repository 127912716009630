//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PictureResponsive from '~/components/PictureResponsive.vue';

export default {
  name: 'AboveTheFold',
  components: {
    PictureResponsive,
  },
};
